<template>
    <div>
        <SubHeader />
        <vue-good-table :columns="columns" :isLoading="loading" :rows="rowsData" :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false}">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'license_pack_name'" :class="[props.row.system_default && 'default hover-text']"
                    class="flex gap-3 pl-3">
                    <router-link :to="{
                        name: 'license-details',  
                        params: {id: props.row.license_pack_id}
                    }"
                        class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">{{ props.row.license_pack_name }}
                    </router-link>
                </div>
                <div v-if="props.column.field === 'tenant_users'" class="flex gap-3 justify-center">
                    <button :disabled="!props.row.tenant_user_count" @click="handleShowModal(props.row)"
                        class="flex disabled:opacity-50 flex-col gap-1 items-center relative cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current text-primary"
                            viewBox="0 0 448 512">
                            <path
                                d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z" />
                        </svg>
                        <span
                            class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-primary-300 p-2 text-white-text">
                            {{ props.row.tenant_user_count }}
                        </span>
                    </button>
                </div>
                <div v-if="props.column.field == 'description'" class="flex justify-center">
                    {{ props.row.description }}                
                </div>
                <div v-if="props.column.field == 'alloted_license_count'" class="flex justify-center">
                    {{ props.row.alloted_license_count }}                
                </div>
                <div v-if="props.column.field == 'balanced_license_count'" class="flex justify-center">
                    {{ props.row.balanced_license_count }}                
                </div>
                <div v-if="props.column.field == 'validity'" class="flex justify-center">
                    {{ props.row.validity }}  Days             
                </div>
                <div v-if="props.column.field == 'tenant_license_count'" class="flex justify-center">
                    {{ props.row.tenant_license_count }}                
                </div>
                <div v-if="props.column.field == 'clients'" class="flex justify-center cursor-pointer" @click="assignedClients(props.row.clients)">
                        <div class="indicator">
                            <span class=" w-2 h-4 indicator-item badge badge-primary">{{props.row.client_count}}</span> 
                            <font-awesome-icon class="w-6  mx-2" icon= "user"/>
                        </div>
                    </div>
            </template>
            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
        <ListModal modalTitle="Asigned Tenant Users" :isLoading="loadingStates.listModal" :lists="getlicenseTenantUsers"
            ref="license-analyst-modal" @handleFilterResult="handleFilterResult($event)">
            <template #item="{ item }">
                <div class="flex justify-between gap-6 w-full">
                    <span class="truncate">
                        {{item.user_name}}
                    </span>
                    <button @click="removeUsers(item.user_id)"
                        class="h-6 w-6  rounded-full bg-gray-100 flex justify-center items-center text-gray-500 hover:bg-gray-200">
                        <font-awesome-icon icon="times" />
                    </button>
                </div>
            </template>
            <template #footer>
                <div class="flex pt-4 justify-between items-center">
                    <button class="btn normal-case px-4 min-h-0 gap-2 flex items-center rounded-xl" @click="handleCloseModal()">Cancel</button>
                    <button class="btn bg-primary normal-case px-4 min-h-0 gap-2 flex items-center hover:bg-primary-600 rounded-xl" @click="saveRemovedTenantUsers()">Save</button>
                </div>
            </template>

        </ListModal>
        <Modal ref="assigned-clients" title='Assigned Clients' :showFooter='false'> 
            <template #content>
                <div class="grid gap-2"> 
                    <span class="border-b text-bold" v-for="data in clientList" :key="data">{{data.client_name}}</span>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import {getLicenseList} from "../services" 
import SubHeader from "@/components/SubHeader"
import Modal from "@/components/modal-content"
const ListModal = () => import("@shared/components/listModal");
import Loader from "@shared/loader";
import axios from "@/axios";
export default {
    name: "taxList",
    components: {
        SubHeader,
        Loader,
        Modal,
        ListModal,
    },
    data() {
        return {
            columns: [{
                label: "License Name",
                field: "license_pack_name",
                sortable: false
            }, {
                label: "Description",
                field: "description",
                sortable: false
            }, {
                label: "Number of License",
                field: "tenant_license_count",
                sortable: false
            }, {
                label: "Assigned Clients",
                field: "clients",
                sortable: false
            }, {
                label: "Assigned Users",
                field: "tenant_users",
                sortable: false
            },{
                label: "Allotted License",
                field: "alloted_license_count",
                sortable: false
            }, {
                label: "Balanced License",
                field: "balanced_license_count",
                sortable: false
            },  {
                label: "Validity",
                field: "validity",
                sortable: false
            },],
            rowsData: [],
            loading: false,
            clientList: [],
            licenseTenantUsers: [],
            removedLicenseTenantUsers: [],
            loadingStates: {
                fetchingLicence: false,
                listModal: false,
                submit: false
            },
            serchValues: "",
        }
    },
    async mounted() {
        this.loading = true
        await this.setLicenseList()
        this.loading = false
    },
    computed: {
        getlicenseTenantUsers() {
            return this.licenseTenantUsers?.filter((el) => el.user_name?.toLowerCase()?.includes(this.serchValues.toLowerCase()));
        },
    },
    methods: {
        async setLicenseList()
        {
            const {data} = await getLicenseList()
            this.rowsData = data

        },
        assignedClients(clients) {
            this.clientList = clients
            this.$refs["assigned-clients"].showModal();
        },
        async handleShowModal(row) {
            this.serchValues = ""
            this.selectedLicenseId = row.license_pack_id
            this.$refs["license-analyst-modal"].showListModal();
            this.getLicenseTenantData(row.tenant_users);
        },
        async getLicenseTenantData(users) {
            this.serchValues = ""
            this.loadingStates.listModal = true;
            try {
                this.licenseTenantUsers =  users.map(el => ({...el, id:el.user_id})) || []
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.listModal = false;
        },
        removeUsers(user_id){
            let removedUser = this.licenseTenantUsers.find(el => el.id == user_id );
            this.removedLicenseTenantUsers?.push(removedUser);
            this.licenseTenantUsers = this.licenseTenantUsers.filter(el => el.id !== user_id )
        },
        async saveRemovedTenantUsers(){
            try {
                if (this.removedLicenseTenantUsers?.length > 0) {
                    let user_ids = this.removedLicenseTenantUsers.map((el)=>el.id)
                    let payload = {
                        license_pack_id: this.selectedLicenseId,
                        user_ids: user_ids
                    }
                    const { data } = await axios.delete("/license-pack/tenant/remove-user", { data: payload });
                    this.selectedLicenseId = null
                    this.$toast.success(data.message || "Analyst Removed");
                }
                this.handleCloseModal()
                await this.setLicenseList();
            } catch (error) {
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg || 'Something is went wrong!')
                } 
                else {
                    this.$toast.error(error?.request?.response?.data?.detail|| 'Something is went wrong!')
                }
            }
        },
        handleCloseModal() {
            this.serchValues = ""
            this.$refs["license-analyst-modal"].closeModal();
        },
}

}
</script>

<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>


























